var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/plugins/profile-edit/Plugin.tsx
import { Plugin } from "plugin-system";
import { PORTAL_APP_BAR_USER_PROFILE_SLOT } from "common-ui";

// src/components/profile-edit/CreatorProfileEdit.tsx
import { useEffect as useEffect2 } from "react";
import {
  useApi,
  useEditCurrentUser,
  useEditOrgNotificationSettings,
  useEditOrgProfile,
  useOrgProfile
} from "common-api";
import { NotificationEvent, useAuth as useAuth2, useFeatureFlags, useMobileEnabled } from "common-ui";
import { useQueryClient } from "@tanstack/react-query";
import { useSnapshot as useSnapshot2 } from "valtio";
import { usePluginRegistry as usePluginRegistry2 } from "plugin-system";
import { ListItemButton, Typography as Typography2 } from "@mui/material";

// src/components/profile-edit/CreatorProfileEditForm.tsx
import {
  Dialog,
  DialogContent,
  Stack,
  Typography,
  IconButton,
  Box,
  TextField,
  Button,
  DialogTitle,
  DialogActions,
  CircularProgress,
  InputAdornment
} from "@mui/material";
import { toFormikValidationSchema } from "zod-formik-adapter";
import CloseIcon from "@mui/icons-material/Close";
import {
  CommonInputLabelStack,
  SingleFileServerUpload,
  FORM_PREVENT_CANCEL,
  LoadingButton,
  FORM_PREVENT_SUBMIT_LOADING,
  FORM_PREVENT_SUBMIT,
  getErrorMessage
} from "common-ui";
import { COMMON_IMAGE_MIME_TYPES, useAuth } from "common-ui";
import { useFormik } from "formik";
import { useSnapshot } from "valtio";
import { useCreatorProfilePageUri, useValidateOrgSlugAvailability } from "common-api";
import { usePluginRegistry } from "plugin-system";
import { useEffect, useState } from "react";

// src/state/profile-edit/index.ts
import { proxy } from "valtio";
import * as z from "zod";
import { isUrl, transformUrl } from "common-ui";
var validationSchema = () => z.object({
  avatarUrl: z.string().min(1).optional(),
  displayName: z.string().min(1).max(32).optional(),
  creatorSlug: z.string({ required_error: "Username is required." }).min(5, { message: "Username must contain at least 5 characters" }).max(75).regex(
    /^[a-zA-Z0-9._-]+$/,
    "Username can only contain letters, numbers, underscores, periods and hyphens."
  ),
  email: z.string().email("It does not look like a valid email.").optional(),
  creatorBio: z.string().max(140, "A maximum of 140 characters are allowed.").optional(),
  oldWebsiteLink: z.string().optional(),
  websiteLink: z.string().transform((val, ctx) => {
    if (!isUrl(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "It does not look like a valid url."
      });
      return z.NEVER;
    }
    return transformUrl(val);
  }).pipe(z.string().url("It does not look like a valid url.")).optional(),
  instagramLink: z.string().min(1).refine((value) => /^.*instagram\.com\/[\w.]+$/i.test(value), {
    message: "Please enter a valid Instagram profile link."
  }).optional(),
  twitterLink: z.string().min(1).refine((value) => /^.*(twitter|x)\.com\/\w+$/i.test(value), {
    message: "Please enter a valid X profile link."
  }).optional(),
  discordLink: z.string().min(1).refine((value) => /^.*discord\.(gg|com)\/.+$/i.test(value), {
    message: "Please enter a valid Discord invite link."
  }).optional()
}).superRefine((val, ctx) => {
  if (!val.websiteLink && val.oldWebsiteLink) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Website link cannot be cleared once set.",
      path: ["websiteLink"]
    });
  }
});
var defaultState = {
  open: false,
  displayName: "",
  avatarUrl: "",
  email: "",
  creatorBio: "",
  creatorSlug: "",
  websiteLink: "",
  instagramLink: "",
  twitterLink: "",
  discordLink: ""
};
var setOrganizationProfileData = (data) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  state.displayName = (_a = data == null ? void 0 : data.data) == null ? void 0 : _a.public_name;
  state.avatarUrl = (_c = (_b = data == null ? void 0 : data.data) == null ? void 0 : _b.media) == null ? void 0 : _c.avatar;
  state.creatorBio = (_d = data == null ? void 0 : data.data) == null ? void 0 : _d.description;
  state.creatorSlug = (_e = data == null ? void 0 : data.data) == null ? void 0 : _e.slug;
  state.websiteLink = (_f = data == null ? void 0 : data.data) == null ? void 0 : _f.website;
  state.instagramLink = (_h = (_g = data == null ? void 0 : data.data) == null ? void 0 : _g.socials) == null ? void 0 : _h.instagram;
  state.twitterLink = (_j = (_i = data == null ? void 0 : data.data) == null ? void 0 : _i.socials) == null ? void 0 : _j.twitter;
  state.discordLink = (_l = (_k = data == null ? void 0 : data.data) == null ? void 0 : _k.socials) == null ? void 0 : _l.discord;
};
var state = proxy(defaultState);
function openProfileEditModal() {
  state.open = true;
}
function closeProfileEditModal() {
  state.open = false;
}
var profile_edit_default = state;

// src/components/profile-edit/CreatorProfileEditForm.tsx
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
var CreatorProfileEditForm = ({
  open,
  handleClose,
  handleSubmit,
  organizationProfile,
  axios
}) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const { platform, auth0AccessToken } = useAuth();
  const { uri: creatorProfilePageUri, isPending: creatorProfilePageUriPending } = useCreatorProfilePageUri(open ? (_a = platform == null ? void 0 : platform.org) == null ? void 0 : _a.id : void 0);
  const defaultState2 = useSnapshot(profile_edit_default);
  const fileUploadUri = axios ? (axios == null ? void 0 : axios.defaults.baseURL) + "/uploads" : "";
  const pluginRegistry = usePluginRegistry();
  const [emailUpdateError, setEmailUpdateError] = useState("");
  const handleOpenNewWindow = () => {
    window.open(creatorProfilePageUri, "_blank", "noopener,noreferrer");
  };
  const {
    data: validateSlugResult,
    mutateAsync: validateSlugAvailability,
    isPending: validatingSlugAvailability,
    reset: resetValidateSlugAvailability
  } = useValidateOrgSlugAvailability();
  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: false,
    initialValues: {
      avatarUrl: defaultState2.avatarUrl || ((_b = organizationProfile == null ? void 0 : organizationProfile.media) == null ? void 0 : _b.avatar) || "",
      displayName: defaultState2.displayName || (organizationProfile == null ? void 0 : organizationProfile.public_name) || "",
      email: ((_c = platform == null ? void 0 : platform.user) == null ? void 0 : _c.email) || "",
      creatorBio: defaultState2.creatorBio || (organizationProfile == null ? void 0 : organizationProfile.description) || "",
      creatorSlug: defaultState2.creatorSlug || (organizationProfile == null ? void 0 : organizationProfile.slug) || "",
      oldWebsiteLink: defaultState2.websiteLink || (organizationProfile == null ? void 0 : organizationProfile.website) || "",
      websiteLink: defaultState2.websiteLink || (organizationProfile == null ? void 0 : organizationProfile.website) || "",
      instagramLink: defaultState2.instagramLink || ((_d = organizationProfile == null ? void 0 : organizationProfile.socials) == null ? void 0 : _d.instagram) || "",
      twitterLink: defaultState2.twitterLink || ((_e = organizationProfile == null ? void 0 : organizationProfile.socials) == null ? void 0 : _e.twitter) || "",
      discordLink: defaultState2.discordLink || ((_f = organizationProfile == null ? void 0 : organizationProfile.socials) == null ? void 0 : _f.discord) || "",
      organizationId: ((_g = platform == null ? void 0 : platform.org) == null ? void 0 : _g.id) || "",
      preventCancel: false,
      preventSubmit: false,
      preventSubmitLoading: false
    },
    validationSchema: toFormikValidationSchema(validationSchema()),
    onSubmit: (_, actions) => {
      actions.submitForm();
    }
  });
  useEffect(() => {
    if (validateSlugResult && !(validateSlugResult == null ? void 0 : validateSlugResult.isAvailable)) {
      formik.setFieldError("creatorSlug", "The username is not available for use.");
    }
  }, [validateSlugResult]);
  useEffect(() => {
    if (formik.values.creatorSlug && formik.values.creatorSlug !== formik.initialValues.creatorSlug) {
      validateSlugAvailability(formik.values.creatorSlug);
    }
  }, [formik.initialValues.creatorSlug, formik.values, validateSlugAvailability]);
  useEffect(() => {
    return pluginRegistry.events.subscribe("api.action.edit-user.error", (e) => {
      var _a2, _b2, _c2;
      setEmailUpdateError(
        ((_c2 = (_b2 = (_a2 = e.data) == null ? void 0 : _a2.error) == null ? void 0 : _b2.response) == null ? void 0 : _c2.status) === 409 ? "Updating email is not allowed for an Auth0 user." : e.data.error.response.status === 422 ? "User with given email already exists" : getErrorMessage("updating your email address")
      );
    });
  }, [pluginRegistry.events]);
  if (creatorProfilePageUriPending) {
    return /* @__PURE__ */ jsx(Fragment, {});
  }
  return /* @__PURE__ */ jsx("form", { onSubmit: formik.handleSubmit, children: /* @__PURE__ */ jsxs(
    Dialog,
    {
      sx: {
        ".MuiDialog-paper": { minHeight: 650, minWidth: 650 }
      },
      open,
      onClose: handleClose,
      children: [
        /* @__PURE__ */ jsxs(DialogTitle, { sx: { justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsxs(Stack, { sx: { flex: 1, display: "flex", flexDirection: "column", rowGap: 1 }, children: [
            /* @__PURE__ */ jsx(Typography, { variant: "h4", children: "Update Phosphor Studio Profile" }),
            /* @__PURE__ */ jsx(Typography, { variant: "body1", children: "Update your profile with a few details about yourself." })
          ] }),
          /* @__PURE__ */ jsx(IconButton, { "aria-label": "close", onClick: handleClose, size: "large", children: /* @__PURE__ */ jsx(CloseIcon, { fontSize: "small" }) })
        ] }),
        /* @__PURE__ */ jsx(
          DialogContent,
          {
            sx: {
              display: "flex",
              flexDirection: "column"
            },
            children: /* @__PURE__ */ jsxs(
              Stack,
              {
                sx: {
                  flex: 2,
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 2,
                  backgroundColor: "white",
                  color: "black"
                },
                children: [
                  /* @__PURE__ */ jsxs(Box, { children: [
                    /* @__PURE__ */ jsx(CommonInputLabelStack, { label: "Profile picture", isOptional: true, forCtrl: "custom" }),
                    /* @__PURE__ */ jsx(
                      SingleFileServerUpload,
                      {
                        testId: "profile-avatar-upload",
                        height: "150px",
                        width: "150px",
                        maxFileSize: "2MB",
                        initialValue: formik.values.avatarUrl,
                        disabled: formik.isSubmitting,
                        acceptedFileTypes: COMMON_IMAGE_MIME_TYPES,
                        titleLabel: "Upload avatar",
                        subtitleLabel: "",
                        captionLabel: ``,
                        uploadUri: fileUploadUri,
                        uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
                        onFileUploadedUrlChange: (organizationBannerUrl) => {
                          formik.setFieldValue("avatarUrl", organizationBannerUrl, true);
                          formik.setFieldTouched("avatarUrl", true, false);
                        },
                        onUploadingChange: (isUploading) => {
                          formik.setFieldValue(FORM_PREVENT_SUBMIT_LOADING, isUploading, false);
                          formik.setFieldValue(FORM_PREVENT_CANCEL, isUploading, false);
                        },
                        allowImageCrop: true,
                        imageCropAspectRatio: "1:1"
                      }
                    )
                  ] }),
                  /* @__PURE__ */ jsx(
                    TextField,
                    {
                      fullWidth: true,
                      id: "displayName",
                      name: "displayName",
                      type: "text",
                      inputProps: {
                        readOnly: formik.isSubmitting
                      },
                      "data-testid": "profile-edit-display-name",
                      label: /* @__PURE__ */ jsx(CommonInputLabelStack, { label: "Display Name", isOptional: true }),
                      autoFocus: true,
                      value: formik.values.displayName,
                      onChange: formik.handleChange,
                      onBlur: formik.handleBlur,
                      error: formik.touched["displayName"] && Boolean(formik.errors["displayName"]),
                      disabled: formik.isSubmitting,
                      helperText: formik.touched["displayName"] && formik.errors["displayName"]
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    TextField,
                    {
                      fullWidth: true,
                      id: "creatorSlug",
                      name: "creatorSlug",
                      type: "text",
                      "data-testid": "profile-edit-creator-slug",
                      label: /* @__PURE__ */ jsx(CommonInputLabelStack, { label: "Username" }),
                      autoFocus: true,
                      autoComplete: "off",
                      value: formik.values.creatorSlug,
                      onChange: (e) => {
                        resetValidateSlugAvailability();
                        return formik.handleChange(e);
                      },
                      onBlur: formik.handleBlur,
                      error: formik.touched["creatorSlug"] && Boolean(formik.errors["creatorSlug"]),
                      disabled: formik.isSubmitting,
                      sx: { ".MuiInputBase-root": { p: 0 }, ".MuiInput-root": { ml: "1px" } },
                      InputProps: {
                        readOnly: formik.isSubmitting,
                        startAdornment: /* @__PURE__ */ jsx(
                          InputAdornment,
                          {
                            position: "start",
                            sx: {
                              height: "100%",
                              maxHeight: "100%",
                              p: 2,
                              backgroundColor: "neutral_50",
                              borderColor: "neutral_100",
                              borderWidth: "0 1px 0 0",
                              borderStyle: "solid",
                              borderTopLeftRadius: 5,
                              borderBottomLeftRadius: 5
                            },
                            children: "phosphor.xyz/"
                          }
                        ),
                        endAdornment: formik.values.creatorSlug !== formik.initialValues.creatorSlug && (!validateSlugResult || validatingSlugAvailability) && /* @__PURE__ */ jsx(InputAdornment, { position: "end", sx: { mr: 1 }, children: /* @__PURE__ */ jsx(CircularProgress, { size: 18 }) })
                      },
                      helperText: ((_h = formik == null ? void 0 : formik.values) == null ? void 0 : _h.creatorSlug) && (!validateSlugResult || validatingSlugAvailability) ? "Validating username..." : formik.touched["creatorSlug"] && Boolean(formik.errors["creatorSlug"]) ? formik.errors["creatorSlug"] : /* @__PURE__ */ jsx(Fragment, { children: "Once you changed the username, all your previous profile links will cease to work. This might take 1-2 min to take effect." })
                    }
                  ),
                  !auth0AccessToken && /* @__PURE__ */ jsx(
                    TextField,
                    {
                      fullWidth: true,
                      id: "email",
                      name: "email",
                      type: "text",
                      inputProps: {
                        readOnly: formik.isSubmitting
                      },
                      autoComplete: "off",
                      "data-testid": "profile-edit-email",
                      label: /* @__PURE__ */ jsx(CommonInputLabelStack, { label: "Email", isOptional: true }),
                      autoFocus: true,
                      value: formik.values.email,
                      onChange: (e) => {
                        setEmailUpdateError("");
                        formik.handleChange(e);
                      },
                      onBlur: formik.handleBlur,
                      error: formik.touched["email"] && Boolean(formik.errors["email"] || Boolean(emailUpdateError)),
                      disabled: formik.isSubmitting,
                      helperText: formik.touched["email"] && Boolean(formik.errors["email"]) ? formik.errors["email"] : emailUpdateError ? /* @__PURE__ */ jsx(Fragment, { children: emailUpdateError }) : /* @__PURE__ */ jsx(Fragment, { children: "Enter your email here to receive updates from Phosphor. Your email address will not be visible publicly. We will send a verification shortly to this address." })
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    TextField,
                    {
                      fullWidth: true,
                      id: "creatorBio",
                      name: "creatorBio",
                      type: "text",
                      multiline: true,
                      rows: 4,
                      InputProps: {
                        readOnly: formik.isSubmitting,
                        endAdornment: /* @__PURE__ */ jsxs(InputAdornment, { position: "start", sx: { alignItems: "start", fontSize: 14 }, children: [
                          (_j = (_i = formik.values.creatorBio) == null ? void 0 : _i.length) != null ? _j : 0,
                          "/140"
                        ] })
                      },
                      sx: { ".MuiInputBase-root": { alignItems: "start" } },
                      placeholder: "Tell us about yourself, characters and emojis allowed.",
                      "data-testid": "profile-edit-bio",
                      label: /* @__PURE__ */ jsx(CommonInputLabelStack, { label: "Bio", isOptional: true }),
                      value: formik.values.creatorBio,
                      onChange: formik.handleChange,
                      onBlur: formik.handleBlur,
                      error: formik.touched["creatorBio"] && Boolean(formik.errors["creatorBio"]),
                      disabled: formik.isSubmitting,
                      helperText: formik.touched["creatorBio"] && formik.errors["creatorBio"]
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    TextField,
                    {
                      fullWidth: true,
                      id: "websiteLink",
                      name: "websiteLink",
                      type: "text",
                      inputProps: {
                        readOnly: formik.isSubmitting
                      },
                      "data-testid": "profile-edit-website-link",
                      label: /* @__PURE__ */ jsx(CommonInputLabelStack, { label: "Website", isOptional: true }),
                      value: formik.values["websiteLink"],
                      onChange: formik.handleChange,
                      onBlur: formik.handleBlur,
                      error: formik.touched["websiteLink"] && Boolean(formik.errors["websiteLink"]),
                      disabled: formik.isSubmitting,
                      helperText: formik.touched["websiteLink"] && formik.errors["websiteLink"]
                    }
                  ),
                  /* @__PURE__ */ jsx(Typography, { variant: "body1", sx: { my: 1 }, children: "Social Links" }),
                  /* @__PURE__ */ jsx(
                    TextField,
                    {
                      fullWidth: true,
                      id: "instagramLink",
                      name: "instagramLink",
                      type: "text",
                      "data-testid": "profile-edit-instagram-link",
                      label: /* @__PURE__ */ jsx(CommonInputLabelStack, { label: "Instagram", isOptional: true }),
                      value: formik.values["instagramLink"],
                      onChange: formik.handleChange,
                      onBlur: formik.handleBlur,
                      inputProps: {
                        readOnly: formik.isSubmitting
                      },
                      error: formik.touched["instagramLink"] && Boolean(formik.errors["instagramLink"]),
                      disabled: formik.isSubmitting,
                      helperText: formik.touched["instagramLink"] && formik.errors["instagramLink"]
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    TextField,
                    {
                      fullWidth: true,
                      id: "twitterLink",
                      name: "twitterLink",
                      type: "text",
                      inputProps: {
                        readOnly: formik.isSubmitting
                      },
                      "data-testid": "profile-edit-twitter-link",
                      label: /* @__PURE__ */ jsx(CommonInputLabelStack, { label: "X", isOptional: true }),
                      value: formik.values["twitterLink"],
                      onChange: formik.handleChange,
                      onBlur: formik.handleBlur,
                      error: formik.touched["twitterLink"] && Boolean(formik.errors["twitterLink"]),
                      disabled: formik.isSubmitting,
                      helperText: formik.touched["twitterLink"] && formik.errors["twitterLink"]
                    }
                  ),
                  /* @__PURE__ */ jsx(
                    TextField,
                    {
                      fullWidth: true,
                      id: "discordLink",
                      name: "discordLink",
                      type: "text",
                      inputProps: {
                        readOnly: formik.isSubmitting
                      },
                      "data-testid": "profile-edit-discord-link",
                      label: /* @__PURE__ */ jsx(CommonInputLabelStack, { label: "Discord Invite", isOptional: true }),
                      value: formik.values["discordLink"],
                      onChange: formik.handleChange,
                      onBlur: formik.handleBlur,
                      error: formik.touched["discordLink"] && Boolean(formik.errors["discordLink"]),
                      disabled: formik.isSubmitting,
                      helperText: formik.touched["discordLink"] && formik.errors["discordLink"]
                    }
                  )
                ]
              }
            )
          }
        ),
        /* @__PURE__ */ jsxs(DialogActions, { children: [
          /* @__PURE__ */ jsx(
            Button,
            {
              "data-testid": "back",
              variant: "outlined",
              sx: {
                "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0"
                },
                color: "black ",
                border: "1px solid black "
              },
              onClick: handleOpenNewWindow,
              children: "View Profile"
            }
          ),
          /* @__PURE__ */ jsx(
            LoadingButton,
            {
              "data-testid": "next",
              variant: "contained",
              type: "submit",
              sx: {
                "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0"
                },
                color: "black"
              },
              loading: formik.isSubmitting || formik.values[FORM_PREVENT_SUBMIT_LOADING],
              title: formik.values[FORM_PREVENT_SUBMIT_LOADING] ? "This button is disabled because there is an action taking place on the form that has yet to complete." : void 0,
              loadingText: formik.isSubmitting ? "Saving..." : "Waiting...",
              disabled: formik.isSubmitting || formik.values[FORM_PREVENT_SUBMIT] === true || formik.values[FORM_PREVENT_SUBMIT_LOADING] === true || !formik.isValid || !formik.values.creatorSlug || formik.values.creatorSlug !== formik.initialValues.creatorSlug && (!validateSlugResult || validatingSlugAvailability),
              onClick: () => __async(void 0, null, function* () {
                let slugValid = formik.values.creatorSlug && formik.values.creatorSlug === (organizationProfile == null ? void 0 : organizationProfile.slug);
                if (formik.values.creatorSlug && !slugValid) {
                  const { isAvailable } = yield validateSlugAvailability(formik.values.creatorSlug);
                  slugValid = isAvailable;
                }
                if (slugValid)
                  handleSubmit == null ? void 0 : handleSubmit(formik.values);
                else {
                  formik.setFieldError("creatorSlug", "The username is not available for use.");
                }
              }),
              children: "Update"
            }
          )
        ] })
      ]
    }
  ) });
};

// src/assets/icons/updateProfile.svg
import * as React from "react";
import { jsx as jsx2 } from "react/jsx-runtime";
var SvgUpdateProfile = (props) => /* @__PURE__ */ jsx2("svg", __spreadProps(__spreadValues({ width: 18, height: 18, viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props), { children: /* @__PURE__ */ jsx2("path", { d: "M13.0514 2.73889L14.4576 1.33265C15.0678 0.722451 16.0572 0.722451 16.6674 1.33265C17.2775 1.94284 17.2775 2.93216 16.6674 3.54235L4.69349 15.5162C4.25292 15.9568 3.70953 16.2806 3.11241 16.4585L0.875 17.125L1.54148 14.8876C1.71936 14.2905 2.04321 13.7471 2.48377 13.3065L13.0514 2.73889ZM13.0514 2.73889L15.25 4.93749", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }) }));
var updateProfile_default = SvgUpdateProfile;

// src/components/profile-edit/CreatorProfileEdit.tsx
import { Fragment as Fragment2, jsx as jsx3, jsxs as jsxs2 } from "react/jsx-runtime";
function CreatorProfileEdit() {
  const isMobile = useMobileEnabled();
  const pluginRegistry = usePluginRegistry2();
  const { platform } = useAuth2();
  const { open } = useSnapshot2(profile_edit_default);
  const queryClient = useQueryClient();
  const { axios } = useApi();
  const organizationId = platform == null ? void 0 : platform.org.id;
  const {
    mutateAsync: saveOrgProfile,
    isSuccess: savedOrgProfile,
    reset: resetSaveOrgProfile,
    isError: saveOrgProfileError
  } = useEditOrgProfile(organizationId);
  const { data: organizationProfile, isPending } = useOrgProfile(organizationId);
  const { captureMarketingUpdatesConsent } = useFeatureFlags();
  const { mutateAsync: updateEmailNotificationSettings } = useEditOrgNotificationSettings();
  const { mutateAsync: updateCurrentUser } = useEditCurrentUser();
  useEffect2(() => {
    if (!savedOrgProfile)
      return;
    pluginRegistry.events.publish(
      new NotificationEvent({
        message: "Profile updated successfully",
        options: {
          type: "success",
          toastId: "profile-update-success"
        }
      })
    );
    if (resetSaveOrgProfile)
      resetSaveOrgProfile();
  }, [savedOrgProfile, pluginRegistry, resetSaveOrgProfile]);
  useEffect2(() => {
    if (!saveOrgProfileError)
      return;
    pluginRegistry.events.publish(
      new NotificationEvent({
        message: "Profile update failed, please try again. If this problem persists, please contact support.",
        contactSupport: true,
        options: {
          type: "error",
          toastId: "profile-update-failed"
        }
      })
    );
    if (resetSaveOrgProfile && saveOrgProfileError)
      resetSaveOrgProfile();
  }, [saveOrgProfileError, pluginRegistry, resetSaveOrgProfile]);
  const handleOpen = () => {
    queryClient.invalidateQueries({ queryKey: ["ORGANIZATION_PROFILE", organizationId] });
    openProfileEditModal();
  };
  const handleClose = () => {
    closeProfileEditModal();
  };
  const handleSubmit = (values) => __async(this, null, function* () {
    var _a;
    try {
      const saveOrgProfileVariables = {
        description: values.creatorBio || null,
        public_name: values.displayName || null,
        slug: values.creatorSlug,
        socials: {
          instagram: values.instagramLink || null,
          twitter: values.twitterLink || null,
          discord: values.discordLink || null
        },
        website: values.websiteLink || null,
        media: {
          avatar: values.avatarUrl || null
        }
      };
      if (values.email !== ((_a = platform == null ? void 0 : platform.user) == null ? void 0 : _a.email)) {
        yield updateCurrentUser({ email: values.email || null });
      }
      if (captureMarketingUpdatesConsent) {
        yield updateEmailNotificationSettings({
          marketing_updates_consent: !!values.email
        });
      }
      yield saveOrgProfile(saveOrgProfileVariables);
      handleClose();
    } catch (e) {
    }
  });
  if (isMobile)
    return /* @__PURE__ */ jsx3(Fragment2, {});
  return /* @__PURE__ */ jsxs2(Fragment2, { children: [
    /* @__PURE__ */ jsxs2(
      ListItemButton,
      {
        sx: {
          display: "flex",
          flexDirection: "row"
        },
        onClick: handleOpen,
        children: [
          /* @__PURE__ */ jsx3(updateProfile_default, {}),
          /* @__PURE__ */ jsx3(Typography2, { fontWeight: "600", marginLeft: "12px", children: isPending ? "Getting Profile ..." : "Update Profile" })
        ]
      }
    ),
    !isPending && /* @__PURE__ */ jsx3(
      CreatorProfileEditForm,
      {
        open,
        handleClose,
        handleSubmit,
        organizationProfile,
        axios
      }
    )
  ] });
}

// src/components/AccountDetails.tsx
import { useCallback, useState as useState3 } from "react";
import { useAuth as useAuth3, useFeatureFlags as useFeatureFlags2 } from "common-ui";
import { useAllPublicNetworks, useRequestProfileClosure } from "common-api";
import CloseIcon3 from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import {
  Button as Button3,
  Dialog as Dialog3,
  DialogActions as DialogActions3,
  DialogContent as DialogContent3,
  DialogTitle as DialogTitle2,
  IconButton as IconButton3,
  ListItemButton as ListItemButton2,
  Stack as Stack3,
  Typography as Typography4
} from "@mui/material";
import { usePluginRegistry as usePluginRegistry3 } from "plugin-system";

// src/components/profile-delete/CreatorProfileDeleteForm.tsx
import { useState as useState2 } from "react";
import Dialog2 from "@mui/material/Dialog";
import { Box as Box2, Button as Button2, DialogActions as DialogActions2, DialogContent as DialogContent2, FormGroup, IconButton as IconButton2, Stack as Stack2, Typography as Typography3 } from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CloseIcon2 from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { jsx as jsx4, jsxs as jsxs3 } from "react/jsx-runtime";
var CreatorProfileDeleteForm = ({
  open,
  handleClose,
  handleDelete
}) => {
  const [deleteEnabled, setDeleteEnabled] = useState2(false);
  const handleAcknowledgmentClicked = () => {
    if (!deleteEnabled) {
      setDeleteEnabled(true);
    } else {
      setDeleteEnabled(false);
    }
  };
  return /* @__PURE__ */ jsx4(
    Dialog2,
    {
      sx: {
        ".MuiDialog-paper": { minHeight: 490, minWidth: 700 }
      },
      open,
      onClose: handleClose,
      children: /* @__PURE__ */ jsx4(Stack2, { children: /* @__PURE__ */ jsxs3(DialogContent2, { dividers: false, children: [
        /* @__PURE__ */ jsxs3(Box2, { sx: { display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 2 }, children: [
          /* @__PURE__ */ jsx4(IconButton2, { color: "error", "aria-label": "error", size: "large", style: { background: "#feebea" }, children: /* @__PURE__ */ jsx4(ErrorOutlineOutlinedIcon, { fontSize: "small" }) }),
          /* @__PURE__ */ jsx4(IconButton2, { "aria-label": "close", onClick: handleClose, size: "large", children: /* @__PURE__ */ jsx4(CloseIcon2, { fontSize: "small" }) })
        ] }),
        /* @__PURE__ */ jsx4(Typography3, { variant: "bodyL", children: "Deleting your profile is permanent and cannot be undone." }),
        /* @__PURE__ */ jsx4(Typography3, { variant: "body1", children: "By choosing to delete your profile, you acknowledge that:" }),
        /* @__PURE__ */ jsx4(Box2, { children: /* @__PURE__ */ jsx4(Typography3, { variant: "body1", children: /* @__PURE__ */ jsxs3("ul", { children: [
          /* @__PURE__ */ jsx4("li", { children: "All active and planned Drops will be ended" }),
          /* @__PURE__ */ jsx4("li", { children: "Your Drops and Collectibles will no longer be visible on Phosphor - blockchain records of your Collectibles will persist" }),
          /* @__PURE__ */ jsx4("li", { children: "Your login credentials will be disabled and deleted" }),
          /* @__PURE__ */ jsx4("li", { children: "Your username will be made available for future users" }),
          /* @__PURE__ */ jsx4("li", { children: "Your profile content will be removed" }),
          /* @__PURE__ */ jsx4("li", { children: "You will be removed from Phosphor-related marketing lists (if present)" }),
          /* @__PURE__ */ jsx4("li", { children: "Audience Lists, Snapshots and Follower Lists will be deleted" }),
          /* @__PURE__ */ jsx4("li", { children: "Your avatar, display name and user handle will no longer be present anywhere on the Phosphor platform" })
        ] }) }) }),
        /* @__PURE__ */ jsxs3(Stack2, { direction: "row", children: [
          /* @__PURE__ */ jsx4(FormGroup, { children: /* @__PURE__ */ jsx4(FormControlLabel, { control: /* @__PURE__ */ jsx4(Checkbox, { onChange: handleAcknowledgmentClicked }), label: "" }) }),
          /* @__PURE__ */ jsx4(Typography3, { variant: "bodyL", children: "I have read the above and I understand that deleting my profile is permanent and cannot be undone." })
        ] }),
        /* @__PURE__ */ jsxs3(DialogActions2, { sx: { justifyContent: "end", margin: 0 }, children: [
          /* @__PURE__ */ jsx4(
            Button2,
            {
              onClick: handleDelete,
              color: "error",
              variant: "outlined",
              disabled: !deleteEnabled,
              sx: {
                "&:hover": {
                  color: "white !important",
                  background: "#ff0000"
                }
              },
              children: "Permanantly Delete My Profile"
            }
          ),
          /* @__PURE__ */ jsx4(
            Button2,
            {
              "data-testid": "next",
              variant: "contained",
              sx: {
                "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0"
                },
                color: (theme) => `${theme.palette.primary.main} !important`,
                "&:hover": {
                  color: "white !important"
                }
              },
              onClick: handleClose,
              children: "Keep My Profile Open"
            }
          )
        ] })
      ] }) })
    }
  );
};

// src/assets/icons/accountDetails.svg
import * as React2 from "react";
import { jsx as jsx5, jsxs as jsxs4 } from "react/jsx-runtime";
var SvgAccountDetails = (props) => /* @__PURE__ */ jsxs4("svg", __spreadProps(__spreadValues({ width: 18, height: 13, viewBox: "0 0 18 13", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props), { children: [
  /* @__PURE__ */ jsx5("path", { d: "M0.875 1.9375C0.875 1.41973 1.29473 1 1.8125 1H6.8125C7.33027 1 7.75 1.41973 7.75 1.9375V5.0625C7.75 5.58027 7.33027 6 6.8125 6H1.8125C1.29473 6 0.875 5.58027 0.875 5.0625V1.9375Z", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx5("path", { d: "M10.875 3.1875C10.875 2.66973 11.2947 2.25 11.8125 2.25H16.1875C16.7053 2.25 17.125 2.66973 17.125 3.1875V10.0625C17.125 10.5803 16.7053 11 16.1875 11H11.8125C11.2947 11 10.875 10.5803 10.875 10.0625V3.1875Z", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }),
  /* @__PURE__ */ jsx5("path", { d: "M2.125 9.4375C2.125 8.91973 2.54473 8.5 3.0625 8.5H7.4375C7.95527 8.5 8.375 8.91973 8.375 9.4375V11.3125C8.375 11.8303 7.95527 12.25 7.4375 12.25H3.0625C2.54473 12.25 2.125 11.8303 2.125 11.3125V9.4375Z", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" })
] }));
var accountDetails_default = SvgAccountDetails;

// src/components/AccountDetails.tsx
import { Fragment as Fragment3, jsx as jsx6, jsxs as jsxs5 } from "react/jsx-runtime";
function AccountDetails() {
  const [open, setOpen] = useState3(false);
  const [deleteProfileOpen, setDeleteProfileOpen] = useState3(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDeleteProfileOpen(false);
  };
  return /* @__PURE__ */ jsxs5(Fragment3, { children: [
    /* @__PURE__ */ jsxs5(
      ListItemButton2,
      {
        onClick: handleOpen,
        sx: {
          color: "primary.main",
          display: "flex",
          flexDirection: "row"
        },
        children: [
          /* @__PURE__ */ jsx6(accountDetails_default, {}),
          /* @__PURE__ */ jsx6(Typography4, { fontWeight: "600", marginLeft: "12px", children: "Account Details" })
        ]
      }
    ),
    /* @__PURE__ */ jsx6(
      AccountDetailsDialog,
      {
        open,
        handleClose,
        setDeleteProfileOpen,
        deleteProfileOpen
      }
    )
  ] });
}
function AccountDetailsRow({ label, value }) {
  return /* @__PURE__ */ jsxs5(Fragment3, { children: [
    /* @__PURE__ */ jsx6(Typography4, { variant: "bodyL", sx: { textAlign: "right" }, children: label }),
    /* @__PURE__ */ jsx6(Typography4, { variant: "body1", children: value })
  ] });
}
function AccountDetailsDialog({
  open,
  handleClose,
  setDeleteProfileOpen,
  deleteProfileOpen
}) {
  var _a, _b;
  const pluginRegistry = usePluginRegistry3();
  const { data: allNetworks, isPending: networksPending } = useAllPublicNetworks();
  const { platform } = useAuth3();
  const organizationId = platform == null ? void 0 : platform.org.id;
  const { profileClosureEnabled } = useFeatureFlags2();
  const { mutateAsync: closeProfile } = useRequestProfileClosure();
  const userEmail = platform == null ? void 0 : platform.user.email;
  const userId = platform == null ? void 0 : platform.user.id;
  const userNetworks = platform == null ? void 0 : platform.org.network_ids;
  const networkDisplayNames = userNetworks == null ? void 0 : userNetworks.map((id) => {
    var _a2;
    return ((_a2 = allNetworks.find((network) => network.id === id)) == null ? void 0 : _a2.name) || `Unknown Network (${id})`;
  }).join(", ");
  const navigate = useNavigate();
  const logout = useCallback(() => {
    navigate("/auth/logout");
  }, [navigate]);
  const handleDeleteProfile = () => __async(this, null, function* () {
    const orgId = platform == null ? void 0 : platform.org.id;
    yield closeProfile({ organizationId: orgId != null ? orgId : "" });
    logout();
  });
  return /* @__PURE__ */ jsxs5(
    Dialog3,
    {
      sx: {
        ".MuiDialog-paper": { minHeight: 300, minWidth: 650 }
      },
      open,
      onClose: handleClose,
      children: [
        /* @__PURE__ */ jsxs5(DialogTitle2, { sx: { justifyContent: "space-between" }, children: [
          /* @__PURE__ */ jsxs5(Stack3, { sx: { flex: 1, display: "flex", flexDirection: "column", rowGap: 1 }, children: [
            /* @__PURE__ */ jsx6(Typography4, { variant: "h4", children: "Account Details" }),
            /* @__PURE__ */ jsx6(Typography4, { variant: "body1", children: "Detailed information about your account. These can be handy to know in the event of any issues." })
          ] }),
          /* @__PURE__ */ jsx6(IconButton3, { "aria-label": "close", onClick: handleClose, size: "large", children: /* @__PURE__ */ jsx6(CloseIcon3, { fontSize: "small" }) })
        ] }),
        /* @__PURE__ */ jsxs5(
          DialogContent3,
          {
            sx: {
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              rowGap: 2,
              columnGap: 2,
              alignItems: "center",
              ".MuiTypography-root": {
                maxWidth: "325px"
              }
            },
            children: [
              userEmail && /* @__PURE__ */ jsx6(AccountDetailsRow, { label: "Account Email:", value: userEmail }),
              ((_a = platform == null ? void 0 : platform.user) == null ? void 0 : _a.address) && /* @__PURE__ */ jsxs5(Fragment3, { children: [
                /* @__PURE__ */ jsx6(Typography4, { variant: "bodyL", sx: { textAlign: "right" }, children: "Account Address:" }),
                /* @__PURE__ */ jsx6(Typography4, { variant: "body1", children: (_b = platform == null ? void 0 : platform.user) == null ? void 0 : _b.address })
              ] }),
              !networksPending && /* @__PURE__ */ jsx6(AccountDetailsRow, { label: "Enabled Networks:", value: networkDisplayNames }),
              /* @__PURE__ */ jsx6(AccountDetailsRow, { label: "Organization ID:", value: organizationId }),
              /* @__PURE__ */ jsx6(AccountDetailsRow, { label: "User ID:", value: userId })
            ]
          }
        ),
        /* @__PURE__ */ jsxs5(DialogActions3, { sx: { justifyContent: "end" }, children: [
          profileClosureEnabled && /* @__PURE__ */ jsx6(
            Button3,
            {
              "data-testid": "next",
              variant: "outlined",
              onClick: () => setDeleteProfileOpen(true),
              color: "error",
              sx: {
                "&:hover": {
                  color: "white !important",
                  background: "#ff0000"
                }
              },
              children: "Delete Account"
            }
          ),
          /* @__PURE__ */ jsx6(
            Button3,
            {
              "data-testid": "next",
              variant: "contained",
              sx: {
                "&.Mui-disabled": {
                  background: "#eaeaea",
                  color: "#c0c0c0"
                },
                color: (theme) => `${theme.palette.primary.main} !important`,
                "&:hover": {
                  color: "white !important"
                }
              },
              onClick: () => {
                pluginRegistry.commands.execute({
                  id: "portal.profile-edit.open"
                });
                handleClose();
              },
              children: "Edit Profile"
            }
          )
        ] }),
        profileClosureEnabled && deleteProfileOpen && /* @__PURE__ */ jsx6(
          CreatorProfileDeleteForm,
          {
            open: deleteProfileOpen,
            handleClose,
            handleDelete: handleDeleteProfile
          }
        )
      ]
    }
  );
}

// src/components/profile-view/CreatorProfileChip.tsx
import { useCreateCreatorProfilePageUri } from "common-api";
import { useAuth as useAuth4 } from "common-ui";
import { ListItemButton as ListItemButton3, Typography as Typography5 } from "@mui/material";

// src/assets/icons/exportIcon.svg
import * as React3 from "react";
import { jsx as jsx7 } from "react/jsx-runtime";
var SvgExportIcon = (props) => /* @__PURE__ */ jsx7("svg", __spreadProps(__spreadValues({ width: 14, height: 14, viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props), { children: /* @__PURE__ */ jsx7("path", { d: "M5.5 2.5H2.5C1.67157 2.5 1 3.17157 1 4V11.5C1 12.3284 1.67157 13 2.5 13H10C10.8284 13 11.5 12.3284 11.5 11.5V8.5M8.5 1H13M13 1V5.5M13 1L5.5 8.5", stroke: "#27272A", strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }) }));
var exportIcon_default = SvgExportIcon;

// src/components/profile-view/CreatorProfileChip.tsx
import { jsx as jsx8, jsxs as jsxs6 } from "react/jsx-runtime";
var ViewCreatorProfile = () => {
  const { platform } = useAuth4();
  const organizationId = platform == null ? void 0 : platform.org.id;
  const {
    mutateAsync: createConsumerProfileUri,
    isPending: consumerProfileUriPending,
    data: consumerProfileUriResult
  } = useCreateCreatorProfilePageUri(organizationId);
  return /* @__PURE__ */ jsxs6(
    ListItemButton3,
    {
      sx: {
        display: "flex",
        flexDirection: "row"
      },
      onClick: () => {
        if (consumerProfileUriResult) {
          const uri = (consumerProfileUriResult == null ? void 0 : consumerProfileUriResult.data).absolute;
          if (uri) {
            window.open(uri, "_blank");
          }
        } else {
          createConsumerProfileUri().then((result) => {
            const uri = (result == null ? void 0 : result.data).absolute;
            if (uri) {
              window.open(uri, "_blank");
            }
          });
        }
      },
      children: [
        /* @__PURE__ */ jsx8(exportIcon_default, {}),
        /* @__PURE__ */ jsx8(Typography5, { fontWeight: "600", marginLeft: "12px", children: consumerProfileUriPending ? "Getting Profile ..." : "View Public Profile" })
      ]
    }
  );
};
var CreatorProfileChip_default = ViewCreatorProfile;

// src/plugins/profile-edit/Plugin.tsx
import { jsx as jsx9 } from "react/jsx-runtime";
var ProfileEditPlugin = class extends Plugin {
  get info() {
    return {
      name: "profile-edit",
      title: "Profile Edit",
      disabledByDefault: false
    };
  }
  activate(registry) {
    return __async(this, null, function* () {
      registry.fillSlot(PORTAL_APP_BAR_USER_PROFILE_SLOT.id, () => /* @__PURE__ */ jsx9(CreatorProfileChip_default, {}));
      registry.fillSlot(PORTAL_APP_BAR_USER_PROFILE_SLOT.id, () => /* @__PURE__ */ jsx9(CreatorProfileEdit, {}));
      registry.fillSlot(PORTAL_APP_BAR_USER_PROFILE_SLOT.id, () => /* @__PURE__ */ jsx9(AccountDetails, {}));
      registry.registerCommand({
        id: "portal.profile-edit.open",
        title: "Open Profile Edit",
        handler: () => {
          openProfileEditModal();
        }
      });
      registry.subscribeEvent("api.action", (event) => {
        const { resource, data, type } = event.data;
        if (resource === "ORGANIZATION_PROFILE" && type === "UPDATE") {
          setOrganizationProfileData(data);
        }
      });
    });
  }
};

// src/plugins/require-wallet/Plugin.tsx
import { RequireAuthPage } from "common-ui";
import { Plugin as Plugin2 } from "plugin-system";

// src/components/onboarding/RequireWalletPage.tsx
import { useEffect as useEffect5, useState as useState4 } from "react";
import { useNavigate as useNavigate3 } from "react-router-dom";
import { usePluginRegistry as usePluginRegistry6 } from "plugin-system";
import { useAccount as useAccount4 } from "wagmi";
import { useAuth as useAuth8, useFeatureFlags as useFeatureFlags4 } from "common-ui";

// src/components/onboarding/StepperContainer.tsx
import { Link as Link3, Stack as Stack9, Step, StepLabel, Stepper, Typography as Typography11, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { OrganizationLogo } from "common-ui";
import CloseIcon4 from "@mui/icons-material/Close";
import { useNavigate as useNavigate2 } from "react-router-dom";

// src/components/profile-setup/ConnectWallet.tsx
import { Link, Stack as Stack4, Typography as Typography6 } from "@mui/material";
import { usePortalIcon as usePortalIcon2 } from "common-ui";

// src/components/onboarding/VerifyWalletButton.tsx
import { Button as Button4, SvgIcon } from "@mui/material";
import { useAccount, useSignMessage } from "wagmi";
import { useCallback as useCallback2, useEffect as useEffect3 } from "react";
import { useAccountModal, useConnectModal } from "@rainbow-me/rainbowkit";
import { useCreateChallengeAuthed, useVerifySignatureAuthed } from "common-api/src/hooks/challenge";
import { useAuth as useAuth5, usePortalIcon } from "common-ui";
import { jsx as jsx10 } from "react/jsx-runtime";
function VerifyWalletButton() {
  const { platform } = useAuth5();
  const { signMessageAsync } = useSignMessage();
  const { address, isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const WalletIcon = usePortalIcon("wallet");
  const FingerprintIcon = usePortalIcon("fingerprint");
  const { mutate: createChallenge, data: createChallengeData } = useCreateChallengeAuthed();
  const { mutateAsync: verifySignature } = useVerifySignatureAuthed();
  const handleVerifyWallet = useCallback2(() => __async(this, null, function* () {
    try {
      if (!address) {
        console.error("User is not connected to the wallet");
        return;
      }
      createChallenge({ body: { address } });
    } catch (error) {
      console.error(error);
    }
  }), [createChallenge, address]);
  const handleSignMessage = useCallback2(() => __async(this, null, function* () {
    const signature = yield signMessageAsync({
      message: createChallengeData ? createChallengeData.message : ""
    });
    verifySignature({
      body: {
        signature,
        challenge_id: createChallengeData ? createChallengeData.id : ""
      }
    });
  }), [createChallengeData, signMessageAsync, verifySignature]);
  useEffect3(() => {
    if (isConnected && createChallengeData) {
      handleSignMessage();
    }
  }, [isConnected, createChallengeData, handleSignMessage]);
  if (!address) {
    return /* @__PURE__ */ jsx10(
      Button4,
      {
        variant: "contained",
        fullWidth: true,
        startIcon: /* @__PURE__ */ jsx10(SvgIcon, { component: WalletIcon, sx: { fontSize: 20 }, inheritViewBox: true }),
        onClick: openConnectModal,
        color: "primary",
        "data-testid": "connect-with-wallet",
        sx: { borderRadius: 2 },
        children: "Connect Wallet"
      }
    );
  }
  if (!(platform == null ? void 0 : platform.user.address)) {
    return /* @__PURE__ */ jsx10(
      Button4,
      {
        variant: "contained",
        fullWidth: true,
        startIcon: /* @__PURE__ */ jsx10(SvgIcon, { component: FingerprintIcon, sx: { fontSize: 20 }, inheritViewBox: true }),
        onClick: handleVerifyWallet,
        "data-testid": "connect-with-wallet",
        sx: { borderRadius: 2 },
        children: "Verify Wallet"
      }
    );
  }
  return /* @__PURE__ */ jsx10(
    Button4,
    {
      variant: "contained",
      fullWidth: true,
      startIcon: /* @__PURE__ */ jsx10(SvgIcon, { component: WalletIcon, sx: { fontSize: 20 }, inheritViewBox: true }),
      onClick: openAccountModal,
      "data-testid": "connect-with-wallet",
      sx: { borderRadius: 2 },
      children: "Disconnect Wallet"
    }
  );
}

// src/components/profile-setup/ConnectWallet.tsx
import { jsx as jsx11, jsxs as jsxs7 } from "react/jsx-runtime";
function ConnectWallet() {
  const WalletIcon = usePortalIcon2("wallet");
  return /* @__PURE__ */ jsxs7(Stack4, { spacing: 6, sx: { width: 400, alignItems: "center", textAlign: "center" }, children: [
    /* @__PURE__ */ jsx11(WalletIcon, {}),
    /* @__PURE__ */ jsxs7(Stack4, { spacing: 3, children: [
      /* @__PURE__ */ jsx11(Typography6, { variant: "h4", fontWeight: 600, children: "Connect your wallet" }),
      /* @__PURE__ */ jsx11(Typography6, { variant: "body1", sx: { color: (theme) => theme.palette.neutral_11 }, children: "Connect your Ethereum wallet to link it to your Phosphor account." }),
      /* @__PURE__ */ jsx11(VerifyWalletButton, {}),
      /* @__PURE__ */ jsxs7(Typography6, { variant: "caption", sx: { color: (theme) => theme.palette.neutral_11 }, children: [
        "Need a wallet?",
        " ",
        /* @__PURE__ */ jsx11(
          Link,
          {
            href: "https://metamask.io/download/",
            target: "_blank",
            rel: "noopener noreferrer",
            sx: { textDecoration: "none" },
            children: "Download Metamask"
          }
        )
      ] })
    ] })
  ] });
}

// src/components/profile-setup/VerifyWallet.tsx
import { Alert, Button as Button5, Stack as Stack5, Typography as Typography7 } from "@mui/material";
import { getErrorMessage as getErrorMessage2, shortenString, usePortalIcon as usePortalIcon3 } from "common-ui";
import { useAccount as useAccount2 } from "wagmi";
import { useAccountModal as useAccountModal2 } from "@rainbow-me/rainbowkit";
import { Fragment as Fragment4, jsx as jsx12, jsxs as jsxs8 } from "react/jsx-runtime";
function VerifyWallet({
  error,
  errorMessage
}) {
  const FingerprintIcon = usePortalIcon3("fingerprint");
  return /* @__PURE__ */ jsxs8(Stack5, { spacing: 6, sx: { width: 400, alignItems: "center", textAlign: "center" }, children: [
    /* @__PURE__ */ jsx12(FingerprintIcon, {}),
    /* @__PURE__ */ jsxs8(Stack5, { spacing: 3, children: [
      /* @__PURE__ */ jsx12(Typography7, { variant: "h4", fontWeight: 600, children: "Verify your wallet" }),
      /* @__PURE__ */ jsx12(Typography7, { variant: "body1", children: "Sign a transaction to confirm ownership of your Ethereum wallet." }),
      /* @__PURE__ */ jsxs8(Stack5, { spacing: 2, children: [
        /* @__PURE__ */ jsx12(WalletAddress, {}),
        /* @__PURE__ */ jsx12(VerifyWalletButton, {})
      ] }),
      error && /* @__PURE__ */ jsx12(Alert, { severity: "error", children: errorMessage != null ? errorMessage : getErrorMessage2("verifying your wallet") })
    ] })
  ] });
}
function WalletAddress() {
  const { address } = useAccount2();
  const { openAccountModal } = useAccountModal2();
  if (address) {
    return /* @__PURE__ */ jsx12(Button5, { variant: "outlined", fullWidth: true, onClick: openAccountModal, sx: { borderRadius: 2 }, children: shortenString(address) });
  }
  return /* @__PURE__ */ jsx12(Fragment4, {});
}

// src/components/profile-setup/Complete.tsx
import { Stack as Stack6, Typography as Typography8 } from "@mui/material";
import { usePortalIcon as usePortalIcon4 } from "common-ui";
import { jsx as jsx13, jsxs as jsxs9 } from "react/jsx-runtime";
function Complete() {
  const SuccessIcon = usePortalIcon4("success");
  return /* @__PURE__ */ jsxs9(Stack6, { spacing: 6, sx: { width: 400, alignItems: "center", textAlign: "center" }, children: [
    /* @__PURE__ */ jsx13(SuccessIcon, {}),
    /* @__PURE__ */ jsxs9(Stack6, { spacing: 3, children: [
      /* @__PURE__ */ jsx13(Typography8, { variant: "h4", fontWeight: 600, children: "You're all set!" }),
      /* @__PURE__ */ jsx13(Typography8, { variant: "body1", children: "Thank you for completing your profile. Welcome to Phosphor!" }),
      /* @__PURE__ */ jsx13(Typography8, { variant: "caption", children: "This wizard will close automatically and you will be redirected to the Phosphor app." })
    ] })
  ] });
}

// src/components/profile-setup/AgreeToTerms.tsx
import { useSnapshot as useSnapshot3 } from "valtio";
import { Alert as Alert2, Box as Box3, Button as Button6, Checkbox as Checkbox2, Link as Link2, Stack as Stack7, Typography as Typography9 } from "@mui/material";
import { useFormik as useFormik2 } from "formik";
import { toFormikValidationSchema as toFormikValidationSchema2 } from "zod-formik-adapter";
import { useEditCurrentUser as useEditCurrentUser2, useEditOrgNotificationSettings as useEditOrgNotificationSettings2, useEditOrgProfile as useEditOrgProfile2 } from "common-api";
import { usePluginRegistry as usePluginRegistry4 } from "plugin-system";
import { getErrorMessage as getErrorMessage3, useAuth as useAuth6, useFeatureFlags as useFeatureFlags3, usePortalIcon as usePortalIcon5 } from "common-ui";

// src/state/profile-setup/username.ts
import { isUrl as isUrl2, transformUrl as transformUrl2 } from "common-ui";
import { proxy as proxy2 } from "valtio";
import * as z2 from "zod";
var validationSchema2 = () => z2.object({
  username: z2.string({ required_error: "Username is required." }).min(5, { message: "Username must contain at least 5 characters" }).max(75).regex(
    /^[a-zA-Z0-9._-]+$/,
    "Username can only contain letters, numbers, underscores, periods and hyphens."
  ),
  profilePic: z2.string().url().optional(),
  displayName: z2.string().optional(),
  email: z2.string().email("It does not look like a valid email.").optional(),
  bio: z2.string().max(140, "A maximum of 140 characters are allowed.").optional(),
  website: z2.string().transform((val, ctx) => {
    if (!isUrl2(val)) {
      ctx.addIssue({
        code: z2.ZodIssueCode.custom,
        message: "It does not look like a valid url."
      });
      return z2.NEVER;
    }
    return transformUrl2(val);
  }).pipe(z2.string().url("It does not look like a valid url.")).optional(),
  instagram: z2.string().min(1).refine((value) => /^.*instagram\.com\/[\w.]+$/i.test(value), {
    message: "Please enter a valid Instagram profile link."
  }).optional(),
  twitter: z2.string().min(1).refine((value) => /^.*(twitter|x)\.com\/\w+$/i.test(value), {
    message: "Please enter a valid X profile link."
  }).optional(),
  discord: z2.string().min(1).refine((value) => /^.*discord\.(gg|com)\/.+$/i.test(value), {
    message: "Please enter a valid Discord invite link."
  }).optional(),
  preventCancel: z2.boolean(),
  preventSubmit: z2.boolean(),
  preventSubmitLoading: z2.boolean()
});
var defaultStateValues = {
  username: "",
  profilePic: "",
  displayName: "",
  email: "",
  bio: "",
  website: "",
  discord: "",
  instagram: "",
  twitter: "",
  preventCancel: false,
  preventSubmit: false,
  preventSubmitLoading: false
};
var state2 = proxy2(__spreadValues({}, defaultStateValues));
var resetState = () => {
  Object.keys(defaultStateValues).forEach((key) => {
    ;
    state2[key] = defaultStateValues[key];
  });
};
var username_default = state2;

// src/state/profile-setup/consent.ts
import { proxy as proxy3 } from "valtio";
import * as z3 from "zod";
var validationSchema3 = z3.object({
  termsConsent: z3.boolean().refine((v) => !!v, { message: "You must agree to the terms to continue." }),
  marketingConsent: z3.boolean().optional()
});
var defaultStateValues2 = {
  termsConsent: false,
  marketingConsent: false
};
var state3 = proxy3(__spreadValues({}, defaultStateValues2));

// src/components/profile-setup/AgreeToTerms.tsx
import { jsx as jsx14, jsxs as jsxs10 } from "react/jsx-runtime";
function AgreeToTerms({
  error,
  errorMessage
}) {
  var _a;
  const usernameSnap = useSnapshot3(state2);
  const TermsIcon = usePortalIcon5("terms");
  const { captureMarketingUpdatesConsent } = useFeatureFlags3();
  const { platform } = useAuth6();
  const editOrgProfile = useEditOrgProfile2((_a = platform == null ? void 0 : platform.org) == null ? void 0 : _a.id);
  const editCurrentUser = useEditCurrentUser2();
  const updateEmailNotificationSettings = useEditOrgNotificationSettings2();
  const pluginRegistry = usePluginRegistry4();
  const formik = useFormik2({
    initialValues: defaultStateValues2,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: toFormikValidationSchema2(validationSchema3),
    onSubmit: () => __async(this, null, function* () {
      var _a2, _b, _c;
      try {
        if (usernameSnap.email) {
          yield editCurrentUser.mutateAsync({ email: usernameSnap.email });
        }
        if (captureMarketingUpdatesConsent && !!usernameSnap.email) {
          yield updateEmailNotificationSettings.mutateAsync({
            enabled: true,
            marketing_updates_consent: true
          });
        }
        yield editOrgProfile.mutateAsync({
          slug: usernameSnap.username || null,
          public_name: usernameSnap.displayName || null,
          description: usernameSnap.bio || null,
          media: {
            avatar: usernameSnap.profilePic || null
          },
          website: usernameSnap.website || null,
          socials: {
            instagram: usernameSnap.instagram || null,
            twitter: usernameSnap.twitter || null,
            discord: usernameSnap.discord || null
          }
        });
        pluginRegistry.events.publish({ id: "agree-to-terms.success" });
      } catch (error2) {
        pluginRegistry.events.publish({
          id: "agree-to-terms.error",
          data: (_c = (_b = (_a2 = error2 == null ? void 0 : error2.response) == null ? void 0 : _a2.data) == null ? void 0 : _b.error) == null ? void 0 : _c.detail
        });
      }
    })
  });
  return /* @__PURE__ */ jsxs10(Stack7, { spacing: 6, sx: { width: 400, alignItems: "center", textAlign: "center" }, children: [
    /* @__PURE__ */ jsx14(TermsIcon, {}),
    /* @__PURE__ */ jsx14("form", { onSubmit: formik.handleSubmit, style: { width: "100%" }, children: /* @__PURE__ */ jsxs10(Stack7, { spacing: 2, sx: { width: "100%" }, children: [
      /* @__PURE__ */ jsx14(Typography9, { variant: "h4", fontWeight: 600, children: "Agree to our terms" }),
      /* @__PURE__ */ jsx14(Typography9, { variant: "body1", children: "Read and agree to our terms and conditions below." }),
      /* @__PURE__ */ jsxs10(Box3, { display: "flex", alignItems: "center", children: [
        /* @__PURE__ */ jsx14(
          Checkbox2,
          {
            checked: formik.values.termsConsent,
            id: "termsConsent",
            name: "termsConsent",
            onBlur: formik.handleBlur,
            onChange: formik.handleChange
          }
        ),
        /* @__PURE__ */ jsxs10(Typography9, { variant: "body2", textAlign: "left", children: [
          "I have read and agree to ConsenSys'",
          " ",
          /* @__PURE__ */ jsx14(Link2, { href: "https://consensys.io/privacy-policy/", target: "_blank", rel: "noopener", children: "Privacy Policy" }),
          " ",
          "and",
          " ",
          /* @__PURE__ */ jsx14(Link2, { href: "https://consensys.io/terms-of-use/", target: "_blank", rel: "noopener", children: "Terms of Use" }),
          "."
        ] })
      ] }),
      /* @__PURE__ */ jsx14(
        Button6,
        {
          variant: "contained",
          type: "submit",
          fullWidth: true,
          "data-testid": "continue-profile-setup",
          disabled: formik.isSubmitting || !formik.isValid || !formik.values["termsConsent"] || formik.isValidating,
          sx: { borderRadius: 2 },
          children: "Continue"
        }
      )
    ] }) }),
    error && /* @__PURE__ */ jsx14(Alert2, { severity: "error", children: errorMessage != null ? errorMessage : getErrorMessage3("creating your profile") })
  ] });
}

// src/components/profile-setup/CreateProfile.tsx
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert as Alert3,
  CircularProgress as CircularProgress2,
  InputAdornment as InputAdornment2,
  Stack as Stack8,
  TextField as TextField2,
  Typography as Typography10
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  COMMON_IMAGE_MIME_TYPES as COMMON_IMAGE_MIME_TYPES2,
  CommonInputLabelStack as CommonInputLabelStack2,
  FORM_PREVENT_CANCEL as FORM_PREVENT_CANCEL2,
  FORM_PREVENT_SUBMIT as FORM_PREVENT_SUBMIT2,
  FORM_PREVENT_SUBMIT_LOADING as FORM_PREVENT_SUBMIT_LOADING2,
  getErrorMessage as getErrorMessage4,
  SingleFileServerUpload as SingleFileServerUpload2,
  usePortalIcon as usePortalIcon6,
  useAuth as useAuth7,
  LoadingButton as LoadingButton2
} from "common-ui";
import { useFormik as useFormik3 } from "formik";
import { toFormikValidationSchema as toFormikValidationSchema3 } from "zod-formik-adapter";
import { useApi as useApi2, usePublicSocialProfile, useValidateOrgSlugAvailability as useValidateOrgSlugAvailability2 } from "common-api";
import { usePluginRegistry as usePluginRegistry5 } from "plugin-system";
import { useEffect as useEffect4, useMemo } from "react";
import { useSnapshot as useSnapshot4 } from "valtio";
import { useAccount as useAccount3 } from "wagmi";
import { Fragment as Fragment5, jsx as jsx15, jsxs as jsxs11 } from "react/jsx-runtime";
function CreateProfile({
  error,
  errorMessage
}) {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  const ProfileIcon = usePortalIcon6("profile");
  const { axios } = useApi2();
  const stateSnapshot = useSnapshot4(username_default);
  const fileUploadUri = axios ? (axios == null ? void 0 : axios.defaults.baseURL) + "/uploads" : "";
  const { address: connectedAddress } = useAccount3();
  const { data: publicSocialProfile } = usePublicSocialProfile(connectedAddress);
  const {
    mutateAsync: validateSlugAvailability,
    data: validateSlugResult,
    isPending: validatingSlugAvailability,
    reset: resetValidateSlugAvailability
  } = useValidateOrgSlugAvailability2();
  const pluginRegistry = usePluginRegistry5();
  const { platform } = useAuth7();
  const initialValues = useMemo(() => {
    var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2, _i2, _j2, _k, _l;
    if (!(stateSnapshot == null ? void 0 : stateSnapshot.username))
      return defaultStateValues;
    return __spreadProps(__spreadValues({}, defaultStateValues), {
      username: (_f2 = (_e2 = (_c2 = (_a2 = publicSocialProfile == null ? void 0 : publicSocialProfile.ens_names) == null ? void 0 : _a2.mainnet) != null ? _c2 : (_b2 = publicSocialProfile == null ? void 0 : publicSocialProfile.ens_names) == null ? void 0 : _b2.linea) != null ? _e2 : (_d2 = publicSocialProfile == null ? void 0 : publicSocialProfile.farcaster) == null ? void 0 : _d2.username) != null ? _f2 : stateSnapshot.username,
      displayName: (_h2 = (_g2 = publicSocialProfile == null ? void 0 : publicSocialProfile.farcaster) == null ? void 0 : _g2.display_name) != null ? _h2 : stateSnapshot.displayName,
      profilePic: (_j2 = (_i2 = publicSocialProfile == null ? void 0 : publicSocialProfile.farcaster) == null ? void 0 : _i2.avatar_url) != null ? _j2 : stateSnapshot.profilePic,
      email: stateSnapshot.email,
      bio: (_l = (_k = publicSocialProfile == null ? void 0 : publicSocialProfile.farcaster) == null ? void 0 : _k.bio) != null ? _l : stateSnapshot.bio,
      website: stateSnapshot.website,
      discord: stateSnapshot.discord,
      instagram: stateSnapshot.instagram,
      twitter: stateSnapshot.twitter
    });
  }, [
    (_a = publicSocialProfile == null ? void 0 : publicSocialProfile.ens_names) == null ? void 0 : _a.linea,
    (_b = publicSocialProfile == null ? void 0 : publicSocialProfile.ens_names) == null ? void 0 : _b.mainnet,
    (_c = publicSocialProfile == null ? void 0 : publicSocialProfile.farcaster) == null ? void 0 : _c.avatar_url,
    (_d = publicSocialProfile == null ? void 0 : publicSocialProfile.farcaster) == null ? void 0 : _d.bio,
    (_e = publicSocialProfile == null ? void 0 : publicSocialProfile.farcaster) == null ? void 0 : _e.display_name,
    (_f = publicSocialProfile == null ? void 0 : publicSocialProfile.farcaster) == null ? void 0 : _f.username,
    stateSnapshot.bio,
    stateSnapshot.discord,
    stateSnapshot.displayName,
    stateSnapshot.email,
    stateSnapshot.instagram,
    stateSnapshot.profilePic,
    stateSnapshot.twitter,
    stateSnapshot.username,
    stateSnapshot.website
  ]);
  const formik = useFormik3({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validationSchema: toFormikValidationSchema3(validationSchema2()),
    onSubmit: (values) => __async(this, null, function* () {
      var _a2, _b2, _c2, _d2, _e2, _f2, _g2, _h2, _i2;
      let slugValid = false;
      if (formik.values.username) {
        const { isAvailable } = yield validateSlugAvailability(formik.values.username);
        slugValid = isAvailable;
      }
      if (slugValid) {
        username_default.username = (_a2 = values.username) != null ? _a2 : void 0;
        username_default.displayName = (_b2 = values.displayName) != null ? _b2 : void 0;
        username_default.profilePic = (_c2 = values.profilePic) != null ? _c2 : void 0;
        username_default.email = (_d2 = values.email) != null ? _d2 : void 0;
        username_default.bio = (_e2 = values.bio) != null ? _e2 : void 0;
        username_default.website = (_f2 = values.website) != null ? _f2 : void 0;
        username_default.discord = (_g2 = values.discord) != null ? _g2 : void 0;
        username_default.instagram = (_h2 = values.instagram) != null ? _h2 : void 0;
        username_default.twitter = (_i2 = values.twitter) != null ? _i2 : void 0;
        pluginRegistry.events.publish({
          id: "create-profile.success"
        });
      } else {
        formik.setFieldError("username", "The username is not available for use.");
      }
    })
  });
  useEffect4(() => {
    if (validateSlugResult && !(validateSlugResult == null ? void 0 : validateSlugResult.isAvailable)) {
      formik == null ? void 0 : formik.setFieldError("username", "The username is not available for use.");
    }
  }, [validateSlugResult == null ? void 0 : validateSlugResult.isAvailable]);
  useEffect4(() => {
    if (formik.values.username && formik.values.username !== formik.initialValues.username) {
      validateSlugAvailability(formik.values.username);
    }
  }, [formik.initialValues.username, formik.values, validateSlugAvailability]);
  return /* @__PURE__ */ jsxs11(Stack8, { spacing: 6, sx: { width: 400, alignItems: "center", textAlign: "center" }, children: [
    /* @__PURE__ */ jsx15(ProfileIcon, {}),
    /* @__PURE__ */ jsx15("form", { onSubmit: formik.handleSubmit, style: { width: "100%" }, children: /* @__PURE__ */ jsxs11(Stack8, { spacing: 2, sx: { width: "100%" }, children: [
      /* @__PURE__ */ jsx15(Typography10, { variant: "h4", fontWeight: 600, children: "Create your profile" }),
      /* @__PURE__ */ jsx15(Typography10, { variant: "body1", children: "Tell the world all about yourself." }),
      /* @__PURE__ */ jsxs11(Stack8, { sx: { maxHeight: "525px", overflowY: "auto", pr: 1, rowGap: 2 }, children: [
        /* @__PURE__ */ jsxs11(Stack8, { children: [
          /* @__PURE__ */ jsx15(
            CommonInputLabelStack2,
            {
              forCtrl: "custom",
              label: "Profile picture",
              isOptional: true,
              variant: "body1"
            }
          ),
          /* @__PURE__ */ jsx15(
            SingleFileServerUpload2,
            {
              testId: "profile-picture-upload",
              height: "200px",
              width: "200px",
              titleLabel: "Upload a file",
              maxFileSize: "2MB",
              initialValue: formik.values["profilePic"],
              disabled: formik.isSubmitting,
              acceptedFileTypes: COMMON_IMAGE_MIME_TYPES2,
              uploadUri: fileUploadUri,
              uploadAuthHeader: axios == null ? void 0 : axios.defaults.headers.Authorization,
              onFileUploadedUrlChange: (organizationBannerUrl) => {
                formik.setFieldValue("profilePic", organizationBannerUrl, true);
                formik.setFieldTouched("profilePic", true, false);
              },
              onUploadingChange: (isUploading) => {
                formik.setFieldValue(FORM_PREVENT_SUBMIT_LOADING2, isUploading, false);
                formik.setFieldValue(FORM_PREVENT_CANCEL2, isUploading, false);
              },
              allowImageCrop: true,
              imageCropAspectRatio: "1:1"
            }
          )
        ] }),
        /* @__PURE__ */ jsx15(
          TextField2,
          {
            fullWidth: true,
            id: "username",
            name: "username",
            type: "text",
            "data-testid": "profile-usename",
            label: /* @__PURE__ */ jsx15(CommonInputLabelStack2, { label: "Username", variant: "body1" }),
            autoFocus: true,
            autoComplete: "off",
            value: formik.values["username"],
            onChange: (e) => {
              resetValidateSlugAvailability();
              return formik.handleChange(e);
            },
            onBlur: formik.handleBlur,
            error: formik.touched["username"] && Boolean(formik.errors["username"]),
            disabled: formik.isSubmitting,
            sx: { ".MuiInputBase-root": { p: 0 }, ".MuiInput-root": { ml: "1px" } },
            InputProps: {
              readOnly: formik.isSubmitting,
              startAdornment: /* @__PURE__ */ jsx15(
                InputAdornment2,
                {
                  position: "start",
                  sx: {
                    height: "100%",
                    maxHeight: "100%",
                    p: 2,
                    backgroundColor: "neutral_50",
                    borderColor: "neutral_100",
                    borderWidth: "0 1px 0 0",
                    borderStyle: "solid",
                    borderTopLeftRadius: 5,
                    borderBottomLeftRadius: 5
                  },
                  children: "phosphor.xyz/"
                }
              ),
              endAdornment: formik.values.username !== formik.initialValues.username && (!validateSlugResult || validatingSlugAvailability) && /* @__PURE__ */ jsx15(InputAdornment2, { position: "end", sx: { mr: 1 }, children: /* @__PURE__ */ jsx15(CircularProgress2, { size: 18 }) })
            },
            helperText: ((_g = formik == null ? void 0 : formik.values) == null ? void 0 : _g.username) && (!validateSlugResult || validatingSlugAvailability) ? "Validating username..." : formik.touched["username"] && Boolean(formik.errors["username"]) ? formik.errors["username"] : /* @__PURE__ */ jsx15(Fragment5, { children: "Alphanumeric only, no caps, spaces, or special characters" })
          }
        ),
        /* @__PURE__ */ jsx15(
          TextField2,
          {
            fullWidth: true,
            id: "displayName",
            name: "displayName",
            type: "text",
            inputProps: {
              readOnly: formik.isSubmitting
            },
            autoComplete: "off",
            "data-testid": "profile-display-name",
            label: /* @__PURE__ */ jsx15(CommonInputLabelStack2, { label: "Display Name", isOptional: true, variant: "body1" }),
            autoFocus: true,
            value: formik.values.displayName,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            error: formik.touched["displayName"] && Boolean(formik.errors["displayName"]),
            disabled: formik.isSubmitting,
            helperText: formik.touched["displayName"] && formik.errors["displayName"]
          }
        ),
        /* @__PURE__ */ jsxs11(
          Accordion,
          {
            disableGutters: true,
            elevation: 0,
            square: true,
            sx: {
              "&.MuiPaper-root": { background: "transparent" },
              "&:before": { display: "none" }
            },
            children: [
              /* @__PURE__ */ jsx15(
                AccordionSummary,
                {
                  expandIcon: /* @__PURE__ */ jsx15(ExpandMoreIcon, {}),
                  "data-testid": "additional-profile-configuration",
                  sx: { justifyContent: "start", fontSize: 14 },
                  children: "Additional profile configurations"
                }
              ),
              /* @__PURE__ */ jsx15(AccordionDetails, { children: /* @__PURE__ */ jsxs11(Stack8, { spacing: 2, children: [
                !((_h = platform == null ? void 0 : platform.user) == null ? void 0 : _h.email) && /* @__PURE__ */ jsx15(
                  TextField2,
                  {
                    fullWidth: true,
                    id: "email",
                    name: "email",
                    type: "text",
                    inputProps: {
                      readOnly: formik.isSubmitting
                    },
                    autoComplete: "off",
                    "data-testid": "profile-email",
                    label: /* @__PURE__ */ jsx15(CommonInputLabelStack2, { label: "Email", isOptional: true, variant: "body1" }),
                    autoFocus: true,
                    value: formik.values.email,
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                    error: formik.touched["email"] && Boolean(formik.errors["email"]),
                    disabled: formik.isSubmitting,
                    helperText: formik.touched["email"] && Boolean(formik.errors["email"]) ? formik.errors["email"] : /* @__PURE__ */ jsx15(Fragment5, { children: "Enter your email here to receive updates from Phosphor. Your email address will not be visible publicly. We will send a verification shortly to this address." })
                  }
                ),
                /* @__PURE__ */ jsx15(
                  TextField2,
                  {
                    fullWidth: true,
                    id: "bio",
                    name: "bio",
                    type: "text",
                    multiline: true,
                    rows: 4,
                    InputProps: {
                      readOnly: formik.isSubmitting,
                      endAdornment: /* @__PURE__ */ jsxs11(
                        InputAdornment2,
                        {
                          position: "start",
                          sx: { alignItems: "start", fontSize: 14, mr: 0, ml: 1 },
                          children: [
                            (_j = (_i = formik.values.bio) == null ? void 0 : _i.length) != null ? _j : 0,
                            "/140"
                          ]
                        }
                      )
                    },
                    sx: { ".MuiInputBase-root": { alignItems: "start" } },
                    placeholder: "Tell us about yourself, characters and emojis allowed.",
                    "data-testid": "profile-bio",
                    label: /* @__PURE__ */ jsx15(CommonInputLabelStack2, { label: "Bio", isOptional: true, variant: "body1" }),
                    value: formik.values.bio,
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                    error: formik.touched["bio"] && Boolean(formik.errors["bio"]),
                    disabled: formik.isSubmitting,
                    helperText: formik.touched["bio"] && formik.errors["bio"]
                  }
                ),
                /* @__PURE__ */ jsx15(
                  TextField2,
                  {
                    fullWidth: true,
                    id: "website",
                    name: "website",
                    type: "text",
                    inputProps: {
                      readOnly: formik.isSubmitting
                    },
                    "data-testid": "profile-website-link",
                    label: /* @__PURE__ */ jsx15(CommonInputLabelStack2, { label: "Website", isOptional: true, variant: "body1" }),
                    value: formik.values["website"],
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                    error: formik.touched["website"] && Boolean(formik.errors["website"]),
                    disabled: formik.isSubmitting,
                    helperText: formik.touched["website"] && formik.errors["website"]
                  }
                ),
                /* @__PURE__ */ jsx15(
                  TextField2,
                  {
                    fullWidth: true,
                    id: "instagram",
                    name: "instagram",
                    type: "text",
                    "data-testid": "profile-instagram-link",
                    label: /* @__PURE__ */ jsx15(CommonInputLabelStack2, { label: "Instagram", isOptional: true, variant: "body1" }),
                    value: formik.values["instagram"],
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                    inputProps: {
                      readOnly: formik.isSubmitting
                    },
                    error: formik.touched["instagram"] && Boolean(formik.errors["instagram"]),
                    disabled: formik.isSubmitting,
                    helperText: formik.touched["instagram"] && formik.errors["instagram"]
                  }
                ),
                /* @__PURE__ */ jsx15(
                  TextField2,
                  {
                    fullWidth: true,
                    id: "twitter",
                    name: "twitter",
                    type: "text",
                    inputProps: {
                      readOnly: formik.isSubmitting
                    },
                    "data-testid": "profile-twitter-link",
                    label: /* @__PURE__ */ jsx15(CommonInputLabelStack2, { label: "X", isOptional: true, variant: "body1" }),
                    value: formik.values["twitter"],
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                    error: formik.touched["twitter"] && Boolean(formik.errors["twitter"]),
                    disabled: formik.isSubmitting,
                    helperText: formik.touched["twitter"] && formik.errors["twitter"]
                  }
                ),
                /* @__PURE__ */ jsx15(
                  TextField2,
                  {
                    fullWidth: true,
                    id: "discord",
                    name: "discord",
                    type: "text",
                    inputProps: {
                      readOnly: formik.isSubmitting
                    },
                    "data-testid": "profile-discord-link",
                    label: /* @__PURE__ */ jsx15(CommonInputLabelStack2, { label: "Discord Invite", isOptional: true, variant: "body1" }),
                    value: formik.values["discord"],
                    onChange: formik.handleChange,
                    onBlur: formik.handleBlur,
                    error: formik.touched["discord"] && Boolean(formik.errors["discord"]),
                    disabled: formik.isSubmitting,
                    helperText: formik.touched["discord"] && formik.errors["discord"]
                  }
                )
              ] }) })
            ]
          }
        )
      ] }),
      /* @__PURE__ */ jsx15(
        LoadingButton2,
        {
          variant: "contained",
          type: "submit",
          fullWidth: true,
          "data-testid": "continue-profile-setup",
          disabled: formik.isSubmitting || formik.values[FORM_PREVENT_SUBMIT2] === true || !formik.isValid || formik.isValidating || !formik.values.username || formik.values.username !== formik.initialValues.username && (!validateSlugResult || validatingSlugAvailability),
          sx: { borderRadius: 2 },
          children: "Continue"
        }
      )
    ] }) }),
    error && /* @__PURE__ */ jsx15(Alert3, { severity: "error", children: errorMessage != null ? errorMessage : getErrorMessage4("creating your profile") })
  ] });
}

// src/components/onboarding/StepperContainer.tsx
import { jsx as jsx16, jsxs as jsxs12 } from "react/jsx-runtime";
function StepperContainer({
  title,
  subtitle,
  steps,
  activeStep,
  error,
  errorMessage,
  backToPreviousStep
}) {
  const theme = useTheme();
  const navigate = useNavigate2();
  return /* @__PURE__ */ jsxs12(Grid, { container: true, height: "100vh", width: "100vw", sx: { minWidth: 1200 }, children: [
    /* @__PURE__ */ jsx16(
      Grid,
      {
        xs: 5,
        sx: {
          backgroundColor: "neutral_50",
          p: 12,
          display: "flex",
          alignItems: "center",
          height: "100%"
        },
        children: /* @__PURE__ */ jsxs12(Stack9, { spacing: 6, children: [
          /* @__PURE__ */ jsx16(OrganizationLogo, {}),
          /* @__PURE__ */ jsxs12(Stack9, { spacing: 2, children: [
            /* @__PURE__ */ jsx16(Typography11, { variant: "h4", sx: { fontWeight: 500 }, children: title }),
            /* @__PURE__ */ jsx16(Typography11, { variant: "body1", sx: { color: "neutral_11" }, children: subtitle }),
            /* @__PURE__ */ jsx16(
              Stepper,
              {
                activeStep,
                orientation: "vertical",
                sx: {
                  ".MuiStepConnector-line": {
                    marginLeft: 1,
                    borderWidth: "0 0 0 1px",
                    borderStyle: "solid",
                    borderColor: "gray",
                    minHeight: 20,
                    marginTop: -1
                  }
                },
                children: steps.map((step, stepIndex) => /* @__PURE__ */ jsx16(
                  Step,
                  {
                    expanded: true,
                    sx: {
                      "&:hover": {
                        cursor: backToPreviousStep && activeStep - 1 === stepIndex ? "pointer" : "default",
                        textDecoration: backToPreviousStep && activeStep - 1 === stepIndex ? "underline" : "none"
                      }
                    },
                    onClick: () => {
                      if (backToPreviousStep && activeStep - 1 === stepIndex) {
                        backToPreviousStep();
                      }
                    },
                    children: /* @__PURE__ */ jsx16(
                      StepLabel,
                      {
                        sx: {
                          display: "flex",
                          alignItems: "start",
                          ".MuiSvgIcon-root": {
                            color: "white",
                            height: 40,
                            width: 40,
                            border: "1px solid gray",
                            borderRadius: "50%",
                            "&.Mui-active": {
                              color: error ? "pink" : "white",
                              borderWidth: error ? 0 : 2,
                              borderStyle: "solid",
                              borderColor: "secondary_500"
                            },
                            "&.Mui-completed": {
                              color: "secondary_500",
                              fill: theme.palette.secondary_500,
                              border: "none"
                            }
                          },
                          ".MuiStepIcon-text": { color: "text.primary", fill: "gray" }
                        },
                        children: /* @__PURE__ */ jsxs12(Stack9, { spacing: 1, sx: { ml: 2 }, children: [
                          /* @__PURE__ */ jsx16(Typography11, { variant: "body1", fontWeight: 600, children: step.label }),
                          /* @__PURE__ */ jsx16(Typography11, { variant: "body2", sx: { color: "neutral_11" }, children: step.description })
                        ] })
                      }
                    )
                  },
                  step.label
                ))
              }
            )
          ] })
        ] })
      }
    ),
    /* @__PURE__ */ jsxs12(
      Grid,
      {
        xs: 7,
        sx: {
          p: 12,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          overflowY: "auto",
          position: "relative",
          " > div": {
            height: "100%",
            justifyContent: "center",
            maxHeight: "100vh"
          }
        },
        children: [
          /* @__PURE__ */ jsxs12(
            Link3,
            {
              sx: {
                position: "fixed",
                top: "5%",
                right: "5%",
                display: "flex",
                columnGap: 1,
                textDecoration: "none"
              },
              onClick: () => {
                resetState();
                navigate("/auth/logout");
              },
              href: "#",
              children: [
                /* @__PURE__ */ jsx16(CloseIcon4, {}),
                "Exit"
              ]
            }
          ),
          /* @__PURE__ */ jsx16(
            StepContent,
            {
              step: activeStep === steps.length ? "COMPLETE" : steps[activeStep].name,
              error,
              errorMessage
            }
          )
        ]
      }
    )
  ] });
}
function StepContent({
  step,
  error,
  errorMessage
}) {
  switch (step) {
    case "CONNECT_WALLET":
      return /* @__PURE__ */ jsx16(ConnectWallet, {});
    case "VERIFY_WALLET":
      return /* @__PURE__ */ jsx16(VerifyWallet, { error, errorMessage });
    case "CREATE_PROFILE":
      return /* @__PURE__ */ jsx16(CreateProfile, { error, errorMessage });
    case "AGREE_TO_TERMS":
      return /* @__PURE__ */ jsx16(AgreeToTerms, { error, errorMessage });
    case "COMPLETE":
      return /* @__PURE__ */ jsx16(Complete, {});
  }
}

// src/components/onboarding/RequireWalletPage.tsx
import { jsx as jsx17 } from "react/jsx-runtime";
var connectWalletSteps = [
  {
    name: "CONNECT_WALLET",
    label: "Connect your wallet",
    description: "Link your Ethereum wallet to your account."
  },
  {
    name: "VERIFY_WALLET",
    label: "Verify your wallet",
    description: "Sign a transaction to confirm ownership."
  },
  {
    name: "COMPLETE",
    label: "Finish up",
    description: "You are all set."
  }
];
function RequireWalletPage() {
  const { platform } = useAuth8();
  const navigate = useNavigate3();
  const pluginRegistry = usePluginRegistry6();
  const { address } = useAccount4();
  const { consumerCreatorAccount } = useFeatureFlags4();
  const [activeStep, setActiveStep] = useState4(0);
  const [error, setError] = useState4(false);
  const [errorMessage, setErrorMessage] = useState4("");
  useEffect5(() => {
    if (!address) {
      setActiveStep(0);
    } else if (!(platform == null ? void 0 : platform.user.address)) {
      setActiveStep(1);
    } else {
      setActiveStep(connectWalletSteps.length);
    }
  }, [address, platform == null ? void 0 : platform.user.address]);
  useEffect5(() => {
    return pluginRegistry.events.subscribe("api.action.create-challenge.error", (e) => {
      setActiveStep(1);
      setError(true);
    });
  }, [pluginRegistry.events]);
  useEffect5(() => {
    return pluginRegistry.events.subscribe("api.action.verify-signature.error", (e) => {
      var _a, _b, _c, _d, _e;
      setActiveStep(1);
      setError(true);
      setErrorMessage((_e = (_d = (_c = (_b = (_a = e.data) == null ? void 0 : _a.error) == null ? void 0 : _b.response) == null ? void 0 : _c.data) == null ? void 0 : _d.error) == null ? void 0 : _e.detail);
    });
  }, [pluginRegistry.events]);
  useEffect5(() => {
    return pluginRegistry.events.subscribe("api.action.verify-signature.success", (e) => {
      setActiveStep(connectWalletSteps.length);
    });
  }, [pluginRegistry.events]);
  useEffect5(() => {
    let handler;
    if (!consumerCreatorAccount || (platform == null ? void 0 : platform.user.address) || activeStep === connectWalletSteps.length) {
      handler = setTimeout(() => {
        navigate("/");
      }, 2e3);
    }
    return () => clearTimeout(handler);
  }, [activeStep, consumerCreatorAccount, navigate, platform == null ? void 0 : platform.user.address]);
  return /* @__PURE__ */ jsx17(
    StepperContainer,
    {
      activeStep,
      error,
      errorMessage,
      steps: connectWalletSteps,
      title: "To continue using Phosphor, please add your Ethereum address.",
      subtitle: "We're updating the way accounts work across Phosphor. Provide an Ethereum address\n              to continue."
    }
  );
}

// src/plugins/require-wallet/Plugin.tsx
import { jsx as jsx18 } from "react/jsx-runtime";
var RequireWalletPlugin = class extends Plugin2 {
  get info() {
    return {
      name: "connect-wallet",
      title: "Connect Wallet",
      disabledByDefault: false
    };
  }
  activate(registry) {
    return __async(this, null, function* () {
      registry.fillSlot("shell.routes", {
        path: "/connect-wallet",
        element: /* @__PURE__ */ jsx18(RequireAuthPage, { children: /* @__PURE__ */ jsx18(RequireWalletPage, {}) })
      });
    });
  }
};

// src/plugins/profile-setup/Plugin.tsx
import { RequireAuthPage as RequireAuthPage2 } from "common-ui";
import { Plugin as Plugin3 } from "plugin-system";

// src/components/onboarding/OnboardingPage.tsx
import { useNavigate as useNavigate4 } from "react-router-dom";
import { usePluginRegistry as usePluginRegistry7 } from "plugin-system";
import { useCallback as useCallback3, useEffect as useEffect6, useMemo as useMemo2, useState as useState5 } from "react";
import { useCurrentPlatformUser, useOrgProfile as useOrgProfile2 } from "common-api";
import { useAuth as useAuth9, useFeatureFlags as useFeatureFlags5 } from "common-ui";
import { useAccount as useAccount5 } from "wagmi";
import { jsx as jsx19 } from "react/jsx-runtime";
var profileSetupSteps = [
  {
    name: "CREATE_PROFILE",
    label: "Create your profile",
    description: "Tell us about yourself, starting with a profile."
  },
  {
    name: "AGREE_TO_TERMS",
    label: "Agree to our terms",
    description: "Read and agree to our terms and conditions."
  }
];
function OnboardingPage() {
  var _a, _b;
  const { platform, auth0AccessToken, authThroughCookies } = useAuth9();
  const navigate = useNavigate4();
  const pluginRegistry = usePluginRegistry7();
  const { address } = useAccount5();
  const { consumerCreatorAccount } = useFeatureFlags5();
  const [activeStep, setActiveStep] = useState5(0);
  const [error, setError] = useState5(false);
  const [errorMessage, setErrorMessage] = useState5("");
  const onboardingSteps = useMemo2(() => {
    return consumerCreatorAccount ? [...connectWalletSteps.slice(0, 2), ...profileSetupSteps, ...connectWalletSteps.slice(-1)] : profileSetupSteps;
  }, [consumerCreatorAccount]);
  const { isSuccess: orgProfileSuccess, data: orgProfile } = useOrgProfile2((_a = platform == null ? void 0 : platform.org) == null ? void 0 : _a.id);
  const { data: platformUser } = useCurrentPlatformUser(!!auth0AccessToken || authThroughCookies);
  useEffect6(() => {
    var _a2;
    if (consumerCreatorAccount) {
      if (!address) {
        setActiveStep(0);
      } else if (!((_a2 = platformUser == null ? void 0 : platformUser.user) == null ? void 0 : _a2.address)) {
        setActiveStep(1);
      } else if (orgProfileSuccess && !(orgProfile == null ? void 0 : orgProfile.slug)) {
        setActiveStep(2);
      } else {
        setActiveStep(onboardingSteps.length);
      }
    } else {
      if (orgProfileSuccess && !(orgProfile == null ? void 0 : orgProfile.slug)) {
        setActiveStep(0);
      } else {
        setActiveStep(onboardingSteps.length);
      }
    }
  }, [
    consumerCreatorAccount,
    address,
    orgProfile == null ? void 0 : orgProfile.slug,
    orgProfileSuccess,
    onboardingSteps.length,
    (_b = platformUser == null ? void 0 : platformUser.user) == null ? void 0 : _b.address
  ]);
  useEffect6(() => {
    return pluginRegistry.events.subscribe("api.action.create-challenge.error", (e) => {
      setActiveStep(1);
      setError(true);
    });
  }, [pluginRegistry.events]);
  useEffect6(() => {
    return pluginRegistry.events.subscribe("api.action.verify-signature.error", (e) => {
      var _a2, _b2, _c, _d, _e;
      setActiveStep(1);
      setError(true);
      setErrorMessage((_e = (_d = (_c = (_b2 = (_a2 = e.data) == null ? void 0 : _a2.error) == null ? void 0 : _b2.response) == null ? void 0 : _c.data) == null ? void 0 : _d.error) == null ? void 0 : _e.detail);
    });
  }, [pluginRegistry.events]);
  useEffect6(() => {
    return pluginRegistry.events.subscribe("api.action.verify-signature.success", (e) => {
      setActiveStep(2);
      setError(false);
    });
  }, [pluginRegistry.events]);
  useEffect6(() => {
    return pluginRegistry.events.subscribe("create-profile.success", (e) => {
      setActiveStep(3);
      setError(false);
    });
  }, [pluginRegistry.events]);
  useEffect6(() => {
    return pluginRegistry.events.subscribe("agree-to-terms.error", (e) => {
      setActiveStep(2);
      setError(true);
      setErrorMessage(e.data && typeof e.data === "string" ? e.data : void 0);
    });
  }, [pluginRegistry.events]);
  useEffect6(() => {
    return pluginRegistry.events.subscribe("agree-to-terms.success", (e) => {
      setActiveStep(4);
      setError(false);
    });
  }, [pluginRegistry.events]);
  useEffect6(() => {
    let handler;
    if (activeStep === onboardingSteps.length) {
      resetState();
      handler = setTimeout(() => {
        navigate("/");
      }, 2e3);
    }
    return () => clearTimeout(handler);
  }, [activeStep, consumerCreatorAccount, navigate, onboardingSteps.length, platform == null ? void 0 : platform.user.address]);
  const backToPreviousStep = useCallback3(() => {
    if (activeStep === 3) {
      setActiveStep(2);
      setError(false);
    }
  }, [activeStep]);
  return /* @__PURE__ */ jsx19(
    StepperContainer,
    {
      activeStep,
      error,
      errorMessage,
      steps: onboardingSteps,
      backToPreviousStep: activeStep === 3 ? backToPreviousStep : void 0,
      title: "Welcome to Phosphor Creator",
      subtitle: "We're excited to have you join us. Follow these steps to set up your account and start creating your NFT drops."
    }
  );
}

// src/plugins/profile-setup/Plugin.tsx
import { jsx as jsx20 } from "react/jsx-runtime";
var ProfileSetupPlugin = class extends Plugin3 {
  get info() {
    return {
      name: "profile-setup",
      title: "Profile Setup",
      disabledByDefault: false
    };
  }
  activate(registry) {
    return __async(this, null, function* () {
      registry.fillSlot("shell.routes", {
        path: "/setup",
        element: /* @__PURE__ */ jsx20(RequireAuthPage2, { children: /* @__PURE__ */ jsx20(OnboardingPage, {}) })
      });
    });
  }
};
export {
  ProfileEditPlugin,
  ProfileSetupPlugin,
  RequireWalletPlugin
};
